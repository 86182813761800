<template>
    <div class="main">
        <div class="banner">
            <div class="big_box">
                <div class="content">
                    <p class="big_title">数据决策  赋能业务</p>
                    <div class="big_item">
                        <div>全域数据</div>
                        <span></span>
                        <div>全景分析</div>
                        <span></span>
                        <div>全面洞察</div>
                    </div>
                </div>
                <div class="com_box">
                    <img class="computer" src="../../assets/img/product/pro_img1.png" alt="">
                </div>
            </div>
            
        </div>
        <div class="bg2"></div>
        <div class="banner">
            <div class="content">
              <div class="list_item">
                  <img src="../../assets/img/product/pro_img2.png" alt="">
                  <p class="item_title">千万级</p>
                  <p class="item_desc">行业数据</p>
              </div>
              <div class="list_item">
                  <img src="../../assets/img/product/pro_img3.png" alt="">
                  <p class="item_title">100,000+</p>
                  <p class="item_desc">筛查风险疑点数据</p>
              </div>
              <div class="list_item">
                  <img src="../../assets/img/product/pro_img4.png" alt="">
                  <p class="item_title">毫秒级</p>
                  <p class="item_desc">响应速度</p>
              </div>
              <div class="list_item">
                  <img src="../../assets/img/product/pro_img5.png" alt="">
                  <p class="item_title">1,700+</p>
                  <p class="item_desc">项目画像</p>
              </div>
            </div>
        </div>
        <div class="banner" style="margin-top:185px">
           <div class="bg3"></div>
            <div class="base">
                <img src="../../assets/img/product/pro_img6.png" class="img6" alt="">
                <p class="com_txt">摒弃原工作模式下合理性分析完全依赖工作人员的素质和工作经验，并且在可见、可信、客观等方面都难以呈现的弊端，以房地产项目为中心的进行多维数据集成,以内控管理和涉税分析为目标提供数据主题服务。</p>
            </div>
        </div>
        <div class="banner" style="margin-top:90px">
           <div class="title_box" style="text-align: center;">
                <p class="desc_t">Product Features</p>
                <p class="bold_t">产品功能</p>
            </div>
            <div class="bg4"></div>
            <div class="list_box">
                <div class="list" style="height:443px">
                    <img src="../../assets/img/product/cir1.png" class="cir1">
                    <div class="txt_box fl">
                        <div class="title_box">
                            <p class="bold_t">建设方分析</p>
                            <p class="desc_t">Analysis of the construction party </p>
                            <div class="line"></div>
                            
                        </div>
                        <div class="info">透过工商注册信息、企业资质、股权关系、与施工方关联关系、历史建设项目、投资人建设项目，宏观层面了解建设方信息。 </div>
                            <img src="../../assets/img/product/btn.png" @click="toLogin" class="tologin">
                    </div>
                    <img src="../../assets/img/product/pro_img7.png" class="show_img fr">
                </div>
                <div class="list" style="height:443px">
                    <div class="txt_box fr">
                        <div class="title_box">
                            <p class="bold_t">施工方分析</p>
                            <p class="desc_t">Analysis of construction party</p>
                            <div class="line"></div>
                            
                        </div>
                        <div class="info">通过对工程合同、历史参与项目、企业资质以及司法舆情，多方面分析施工方，发现潜在的风险。  </div>
                            <img src="../../assets/img/product/btn.png" @click="toLogin" class="tologin">
                    </div>
                    <img src="../../assets/img/product/pro_img8.png" class="fl">
                </div>
                <div class="list" style="height:412px">
                    <img src="../../assets/img/product/cir2.png" class="cir2">
                    <div class="txt_box fl">
                        <div class="title_box">
                            <p class="bold_t">项目分析</p>
                            <p class="desc_t">Project analysis</p>
                            <div class="line"></div>
                            
                        </div>
                        <div class="info">提供项目内、外部数据分析能力，系统提供的功能包括： 项目画像、周边情况、成本分析、同类型项目税负预测。  </div>
                            <img src="../../assets/img/product/btn.png" @click="toLogin" class="tologin">
                    </div>
                    <img src="../../assets/img/product/pro_img9.png" class="fr img9">
                </div>
                <div class="list" style="height:400px">
                    <div class="txt_box fr">
                        <div class="title_box">
                            <p class="bold_t">风险分析</p>
                            <p class="desc_t">Risk analysis</p>
                            <div class="line"></div>
                            
                        </div>
                        <div class="info">集成裁判文书、新闻舆情海量数据，挖掘企业和项目负面信息，做好第一印象的“照妖镜”。   </div>
                            <img src="../../assets/img/product/btn.png" @click="toLogin" class="tologin">
                    </div>
                    <img src="../../assets/img/product/pro_img10.png" class="fl">
                </div>
                <div class="list" style="height:420px">
                    <img src="../../assets/img/product/cir3.png" class="cir3">
                    <div class="txt_box fl">
                        <div class="title_box">
                            <p class="bold_t">测算报告</p>
                            <p class="desc_t">Calculation report</p>
                            <div class="line"></div>
                            
                        </div>
                        <div class="info">运用土地交易数据、材料造价、房价信息等数据和数据模型，进行税收测算，拓展市场能力。  </div>
                            <img src="../../assets/img/product/btn.png" @click="toLogin" class="tologin">
                    </div>
                    <img src="../../assets/img/product/pro_img11.png" class="fr">
                </div>
                <div class="list" style="height:458px">
                    <div class="txt_box fr">
                        <div class="title_box">
                            <p class="bold_t">开放数据</p>
                            <p class="desc_t">Open data</p>
                            <div class="line"></div>
                            
                        </div>
                        <div class="info">获取围绕清算工作的多方数据，数据触手可得。包括：土地交易数据、材料造价、网签数据、政策法规、政策口径、历史房价信息等等。    </div>
                            <img src="../../assets/img/product/btn.png" @click="toLogin" class="tologin">
                    </div>
                    <img src="../../assets/img/product/pro_img12.png" class="fl img12">
                </div>
                <div class="list" style="height:500px">
                    <img src="../../assets/img/product/cir4.png" class="cir4">
                    <div class="txt_box fl">
                        <div class="title_box">
                            <p class="bold_t">项目画像</p>
                            <p class="desc_t">Project portrait</p>
                            <div class="line"></div>
                            
                        </div>
                        <div class="info">系统生成项目核心指标，清算工作焦点自动进行汇集，快速定位数据问题。  项目画像、周边情况、成本分析、同类型项目税负预测。  </div>
                            <img src="../../assets/img/product/btn.png" @click="toLogin" class="tologin">
                    </div>
                    <img src="../../assets/img/product/pro_img13.png" class="fr img9">
                </div>
            </div>
            
            
        </div>



     
        <foot></foot>
    </div>
</template>
<script>
import foot from '@/page/website/component/foot.vue';
export default {

    components: {
        foot,
    },
    data() {
        return {}
    },
    created() {
        
    },
    mounted() {
    },
    methods: {
        toLogin(){
            var url = 'http://' + window.location.host + '/#/login'
            window.open(url,'_blank')
        }
        
    }
}
</script>

<style scoped lang="less">
::-webkit-scrollbar {
    width: 0;
}

.main{
    width: 100%;
    overflow-y: scroll;
    position: relative;
}
.banner{
    width: 100%;
    position: relative;
}
.fl{
    float: left;
}
.fr{
    float: right;
}
.big_box{
    width: 100%;
    height: 494px;
    position: relative;
    background: url('../../assets/img/product/pro_bg1.png') repeat-x;
    margin-bottom: 176px;
    .big_title{
        font-size: 44px;
        font-weight: 600;
        color: #FFFFFF;
        margin-top: 159px;
        margin-left: 45px;
    }
    .big_item{
        overflow: hidden;
        margin-left: 45px;
        margin-top: 61px;
        div{
            font-size: 24px;
            font-weight: 400;
            height: 28px;
            line-height: 28px;
            color: #FFFFFF;
            float: left;
        }
        span{
            width: 2px;
            height: 28px;
            float: left;
            background: #FFFFFF;
            margin: 0 16px;
        }
    }
    .computer{
        position: absolute;
        right: 50px;
        top: 65px;
    }
    .com_box{
        width: 1200px;
        position: absolute;
        left: 50%;
        margin-left: -600px;
        top: 0;
        height: 494px;

    }
}
.base{
    width: 1200px;
    margin: 0 auto;
    position: relative;
    .img6{
        margin-left: -138px;
        margin-top: -20px;
    }
    .com_txt{
        width: 564px;
        height: 140px;
        font-size: 20px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 35px;
        float: right;
        margin-top: 115px;
        margin-right: 40px;
    }
}
.content{
    width: 1200px;
    overflow: hidden;
    margin: 0 auto;
    position: relative;
    .list_item{
        width: 213px;
        height: 320px;
        float: left;
        margin-right: 88px;
        overflow: hidden;
        text-align: center;
        .item_title{
            font-size: 36px;
            font-weight: 600;
            color: #333333;
            margin-bottom: 19px;
        }
        .item_desc{
            font-size: 24px;
            font-weight: 600;
            color: #969696;
        }
    }
    .list_item:last-child{
        margin-right: 0;
    }
}
.title_box{
    .bold_t{
        font-size: 36px;
        font-weight: 600;
        color: #333333;
        line-height: 50px;
    }
    .desc_t{
        font-size: 18px;
        font-weight: 600;
        color: #B7B7B7;
        line-height: 25px;
    }
}
.list_box{
    width: 1200px;
    margin: 0 auto;
    height: 3183px;
    position: relative;
    z-index: 1;
    .list{
        width: 100%;
        position: relative;
        z-index: 1;
        .show_img{
            margin-top: 47px;
        }
        .img9{
            margin-right: -30px;
            z-index: 1;
            position: relative;
        }
        .img12{
            margin-left: -30px;
            z-index: 1;
            position: relative;
        }
        .txt_box{
            padding-top: 76px;
            padding-left: 35px;
            width: 493px;
            .bold_t{
                font-size: 30px;
            }
            .desc_t{
                font-size: 14px;
            }
            .line{
                width: 52px;
                height: 4px;
                background: #185BCE;
                margin-top: 15px;
                margin-bottom: 36px;
            }
            .info{
                width: 493px;
                height: 75px;
                font-size: 18px;
                font-weight: 400;
                color: #333333;
                line-height: 25px;
            }
            .tologin{
                float: right;
                margin-top: 48px;
                cursor: pointer;
            }
        }
    }
}
.cir1{
    position: absolute;
    left: -90px;
    top: 0;
    z-index: 0;
}
.cir2{
    position: absolute;
    right: -40px;
    top: 0;
    z-index: 0;
}
.cir3{
    position: absolute;
    left: -105px;
    top: 0;
    z-index: 0;
}
.cir4{
    position: absolute;
    right: -170px;
    bottom: -270px;
    z-index: 0;
}
.bg2{
    width: 932px;
    height: 668px;
    position: absolute;
    left: 0;
    top: 510px;
    background: url('../../assets/img/product/pro_bg2.png') no-repeat;
    z-index: 0;
}
.bg3{
    width: 1173px;
    height: 361px;
    position: absolute;
    right: 0;
    top: 0;
    background: url('../../assets/img/product/pro_bg3.png') no-repeat;
    z-index: 0;
}
.bg4{
    width: 639px;
    height: 2327px;
    position: absolute;
    left: 50%;
    margin-left: -330px;
    top: 427px;
    background: url('../../assets/img/product/pro_bg4.png') no-repeat;
    z-index: 0;
}


</style>