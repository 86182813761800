<template>
    <div class="foot_box">
        <div class="banner">
            <p class="ban_title">即刻体验项目画像与区域测算</p>
            <div class="tologin" @click="toLogin">立即注册  免费使用</div>
        </div>
        <div class="footer">
            <div class="foot_content">
                <div class="bg"></div>
                <div class="left_box" style="margin-right:153px">
                    <p class="foot_title">联系我们</p>
                    <p>地址：上海市上海财经大学国家大学科技园3号楼305</p>
                    <p>电话：400-8778-367</p>
                    <p>邮箱：puduoinfo@126.com</p>
                </div>
                <div class="left_box" style="padding-left:0">
                    <p class="foot_title">关注我们</p>
                    <img src="../../../assets/img/ewm1.png" alt="">
                    <img src="../../../assets/img/ewm2.png" alt="">
                    <img style="margin-right:0" src="../../../assets/img/ewm3.png" alt="">
                </div>
                <p class="copyright">Copyright © 2021-2022 浦舵信息科技（上海）有限公司 <img src="../../../assets/img/beian.png" class="beian"><a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer"> 备案号：沪ICP备20019236号-1</a> <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011002005161" target="_blank" rel="noopener noreferrer"> 沪公网安备 31011002005161号</a></p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
        }
    },

    mounted() {
    },
    methods: {
       toLogin(){
            var url = 'http://' + window.location.host + '/#/login'
            window.open(url,'_blank')
        }
        
    }
 
  
}
</script>

<style scoped lang="less">
.foot_box{
    position: relative;
    z-index: 10;
}
.banner{
    width: 100%;
    height: 301px;
    background: url('../../../assets/img/index_bg7.png') repeat-x;
    .ban_title{
        text-align: center;
        padding-top: 79px;
        font-size: 40px;
        font-weight: 600;
        color: #FFFFFF;
    }
    .tologin{
        width: 188px;
        height: 49px;
        border: 2px solid #FFFFFF;
        margin: 58px auto 0;
        line-height: 45px;
        box-sizing: border-box;
        text-align: center;
        color: #FFFFFF;
        cursor: pointer;
    }
}
.footer{
    width: 100%;
    height: 438px;
    background: #303948;
    .foot_content{
        width: 1140px;
        height: 438px;
        margin: 0 auto;
        position: relative;
    }
    .bg{
        width: 822px;
        height: 430px;
        background: url('../../../assets/img/index_bg8.png') no-repeat;
        position: absolute;
        top: 0;
        left: 148px;
    }
    .left_box{
        float: left;
        padding-top: 70px;
        padding-left: 10px;
        .foot_title{
            font-size: 24px;
            font-weight: 600;
            color: #FFFFFF;
            margin-bottom: 39px;
        }
        p{
            font-size: 18px;
            font-weight: 400;
            color: #FFFFFF;
            margin-bottom: 47px;
        }
        img{
            margin-right: 24px;
        }
    }
    .copyright{
        width: 100%;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        color: #C8C9CC;
        line-height: 22px;
        position: absolute;
        bottom: 37px;
        a{
            color: #C8C9CC;
            text-decoration: none;
        }
    }
    .beian{
        margin-left: 41px;
        margin-right: 9px;
    }
}
</style>